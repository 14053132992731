import {
  faBell,
  faBellSlash,
  faCalendarPlus,
  faCalendarTimes,
  faEye,
  faEyeSlash,
  faHeart,
  faInfoCircle,
  faStar,
} from "@fortawesome/pro-regular-svg-icons"
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons"
import { faTimesSquare } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "@reach/router"
import ButterToast from "butter-toast"
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useFilters } from "../../hooks/use-filters"
import { useModal } from "../../hooks/use-modal"
import { useStyle } from "../../hooks/use-style"
import { getCachedSecret, useUser } from "../../hooks/use-user"
import { rpc } from "../../services/node"
import { prettyAmount } from "../../services/number"
import { darkTheme, lightTheme } from "../../services/theme"
import { Channel } from "../../types"
import DropdownCompactMenu from "../dropdown-compact-menu"
import { SelectGateway } from "../gateway/select-gateway"
import Avatar from "../layout/avatar"
import Description from "../layout/description"
import Panel from "../layout/panel"
import HtmlTooltip from "../widgets/html-tooltip"
import SupportChannel from "./support-channel"
import { GET_channel } from "../../services/http"
import SubscribeOptions from "../dialogs/subscribe-options"
import { convertChannel } from "../../services/content"
import { useSideMode, SideMode } from "../../hooks/use-side-mode"

type Props = {
  channel: Channel
  donationsEnabled?: boolean
}

const ChannelView: FunctionComponent<Props> = ({
  channel,
  donationsEnabled = false,
}) => {
  const { updateFilters } = useFilters()
  const { sideMode } = useSideMode()
  const {
    user,
    subscribe,
    unsubscribe,
    isSubscribed,
    updateChannelViews,
    scheduleBalanceQuery,
    getRepeatingDonation,
    deleteRepeatingDonation,
    setBalance,
  } = useUser()
  /*
  const isLiked = useMemo(() => channelLikes.includes(channel?.id), [
    channelLikes,
    channel,
  ])
*/
  /*
  const enoughFunds = useMemo(() => {
    console.log("donationsEnabled " + donationsEnabled)
    return donationsEnabled
  }, [donationsEnabled, channel])
*/

  const toggleSubscription = useCallback(async () => {
    if (isSubscribed(channel.id)) {
      await unsubscribe(channel)
    } else {
      if (channel) {
        if (channel.owner && channel.owner.length > 0) {
          console.log(channel.owner)
          GET_channel(channel.owner).then(res => {
            let owner = convertChannel(res)
            console.log(owner)
            setContent(
              <SubscribeOptions
                channel={channel}
                owner={owner}
                close={ch => {
                  setContent(null)
                  if (ch) {
                    subscribe(ch, null)
                  }
                }}
              />
            )
          })
        } else {
          await subscribe(channel, null)
        }
      }
    }
  }, [channel?.id, subscribe, unsubscribe, isSubscribed])

  /*
  const toggleNotifications_ = useCallback(async () => {
    console.log("toggleNotifications")
    await toggleNotifications(channel)
  }, [channel, toggleNotifications])
*/
  /*
  const donate = useCallback(async () => {
    if (isSubscribed(channel.id)) {
      await unsubscribe(channel)
    } else {
      await subscribe(channel)
    }
  }, [channel?.id, subscribe, unsubscribe, isSubscribed])
*/

  const subscribed = channel ? isSubscribed(channel.id) : false
  const { content, setContent } = useModal()

  const [regularSupport, setRegularSupport] = useState("0")
  const [isDonateDialogOpen, setDonateDialogOpen] = useState(false)
  const openDonateDialog = useCallback(() => setDonateDialogOpen(true), [])
  const closeDonateDialog = useCallback((account: any) => {
    /*
    if (account != null && !account.target) {
      const msg = JSON.stringify(account)

      rpc("add_donation_address", msg, user?.id, getCachedSecret(), function (
        res
      ) {
        setState({
          isLoaded: false,
          hasMore: true,
          items: [],
          error: null,
        })
      })
    }*/
    setDonateDialogOpen(false)
  }, [])

  const openSupportDialog = useCallback(() => {
    setContent(
      <SupportChannel channel={channel} close={() => setContent(null)} />
    )
  }, [channel])

  const css = useStyle(
    theme => ({
      backgroundColor:
        theme === "light"
          ? lightTheme.color.background.primary
          : darkTheme.color.background.secondary,
      hoverButtonBackgroundColor:
        theme === "light"
          ? lightTheme.color.background.secondary
          : darkTheme.color.background.primary,

      actionButtonBackgroundColorSet:
        theme === "light" ? lightTheme.color.border : darkTheme.color.border,

      actionButtonBackgroundColorUnset:
        theme === "light" ? lightTheme.color.active : darkTheme.color.active,

      actionButtonTextColor:
        theme === "light" || !subscribed
          ? lightTheme.color.text.primary
          : darkTheme.color.text.primary,
      borderColor:
        theme === "light" ? lightTheme.color.border : darkTheme.color.border,
      textColor:
        theme === "light"
          ? lightTheme.color.text.primary
          : darkTheme.color.text.primary,
      infoColor:
        theme === "light"
          ? lightTheme.color.text.secondary
          : darkTheme.color.text.secondary,
    }),
    [subscribed]
  )

  const resetFilters = useCallback(() => {
    console.log("channel::resetFilters")
    updateFilters({ channel_ids: [] })
  }, [updateFilters])

  const onUpdate = useCallback((newChannel: Channel) => {}, [channel])

  useEffect(() => {
    if (channel) updateChannelViews(channel.id)
  }, [channel])

  const showGatewaySelector = useCallback(() => {
    setContent(
      <SelectGateway
        channel={channel}
        close={() => setContent(null)}
        gateways={channel.gateways}
      />
    )
  }, [channel])

  const repeatingDonation = channel ? getRepeatingDonation(channel.id) : null
  const printRepeatingDonationData = () => {
    if (repeatingDonation) {
      /*const periods = [
        { id: 0, label: "week" },
        { id: 1, label: "month" },
        { id: 2, label: "quarter" },
        { id: 3, label: "year" },
      ]
      let result = ""
      for (var i = 0; i < periods.length; i++) {
        if (periods[i].id == repeatingDonation.period) {
          //repeatingDonation.added
          result =
            "recurring donation: " +
            (repeatingDonation.amount / 100).toFixed(2) +
            " / " +
            periods[i].label
          break
        }
      }*/
      return (
        "recurring donation: " +
        prettyAmount(repeatingDonation.amount) +
        " per month"
      )
    }
  }

  const removeRegularSupport_ = useCallback(async () => {
    deleteRepeatingDonation(channel, true)
  }, [channel?.id, deleteRepeatingDonation])

  return (
    <>
      {channel && sideMode == SideMode.Content && (
        <article>
          <Panel url={channel ? channel.url : ""}>
            {{
              header: (
                <div className="info">
                  {channel.image && channel.image.length ? (
                    <a
                      href={channel.url}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <Avatar
                        image={channel.image || undefined}
                        size={50}
                        label={channel.title}
                      />
                    </a>
                  ) : (
                    channel.icon &&
                    channel.icon.length && (
                      <a
                        href={channel.url}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <Avatar
                          image={channel.icon || undefined}
                          size={50}
                          label={channel.title}
                        />
                      </a>
                    )
                  )}
                  <div className="title">
                    <h1 title={channel.title}>{channel.title}</h1>
                    {channel.alias_host && <p>on {channel.alias_host}</p>}
                    <p>{/*(channel.amount / 100).toFixed(2)*/}</p>
                  </div>
                </div>
              ),
              content: (
                <div className="description">
                  <Description linkify>{channel.description}</Description>
                  {channel.url && (
                    <div
                      style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                    >
                      <a
                        className="external-link"
                        href={"https://" + channel.url}
                        target="_blank"
                      >
                        {channel.url}
                      </a>
                    </div>
                  )}
                  {channel.owner && channel.owner.length && (
                    <div
                      style={{
                        display: "inline-flex",
                        verticalAlign: "middle",
                      }}
                    >
                      <Link
                        to={"/channel/" + channel.owner}
                        style={{
                          fontSize: "12px",
                          color: "rgb(96, 96, 96)",
                          margin: "auto",
                          textDecoration: "none",
                        }}
                      >
                        {channel.owner}
                      </Link>
                      <div
                        style={{
                          marginLeft: "5px",
                          color: "rgb(96, 96, 96)",
                        }}
                      >
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </div>
                    </div>
                  )}
                </div>
              ),
              upperToolbar: (
                <div style={{ /*opacity: 0.7,*/ display: "flex" }}>
                  <div className="options">
                    <DropdownCompactMenu
                      item={null}
                      channel={channel}
                      onUpdate={undefined}
                      onMute={undefined}
                    />
                  </div>
                  <div className="highlighted">
                    <FontAwesomeIcon
                      icon={faTimesSquare}
                      onClick={resetFilters}
                      style={{ backgroundColor: "rgb(251, 230, 162)" }}
                    />
                  </div>
                </div>
              ),
              lowerToolbar: user && (
                <div>
                  {repeatingDonation && (
                    <div className="repeating">
                      <div className="star">
                        <FontAwesomeIcon icon={faStar} fixedWidth size="xs" />
                      </div>

                      {printRepeatingDonationData()}
                    </div>
                  )}
                  <div className="toolbar">
                    {isSubscribed(channel.id) ? (
                      <button
                        className="subscribe-button set"
                        onClick={toggleSubscription}
                      >
                        <div style={{ display: "inline-flex" }}>
                          <div className="btn-icon">
                            <FontAwesomeIcon icon={faEyeSlash} />
                          </div>
                          Unsubscribe
                        </div>
                      </button>
                    ) : (
                      <button
                        className="subscribe-button unset"
                        onClick={toggleSubscription}
                      >
                        <div style={{ display: "inline-flex" }}>
                          <div className="btn-icon">
                            <FontAwesomeIcon icon={faEye} />
                          </div>
                          Subscribe
                        </div>
                      </button>
                    )}
                    {/*
                    <HtmlTooltip
                      title="Notify when new content is published"
                      placement="top"
                    >
                      {isNotificationSet(channel.id) ? (
                        <button
                          className="small-button set"
                          onClick={toggleNotifications_}
                        >
                          <div className="">
                            <FontAwesomeIcon icon={faBellSlash} />
                          </div>
                        </button>
                      ) : (
                        <button
                          className="small-button unset"
                          onClick={toggleNotifications_}
                        >
                          <div className="">
                            <FontAwesomeIcon icon={faBell} />
                          </div>
                        </button>
                      )}
                    </HtmlTooltip>
                      */}
                    {user && user.account_type == "global" ? (
                      <>
                        {repeatingDonation == null ? (
                          <HtmlTooltip
                            title="Add recurring donation"
                            placement="top"
                          >
                            <button
                              className="small-button unset"
                              onClick={openSupportDialog}
                            >
                              <div className="">
                                <FontAwesomeIcon icon={faCalendarPlus} />
                              </div>
                            </button>
                          </HtmlTooltip>
                        ) : (
                          <HtmlTooltip
                            title="Remove recurring donation"
                            placement="top"
                          >
                            <button
                              className="small-button set"
                              onClick={removeRegularSupport_}
                            >
                              <div className="">
                                <FontAwesomeIcon icon={faCalendarTimes} />
                              </div>
                            </button>
                          </HtmlTooltip>
                        )}
                      </>
                    ) : (
                      <HtmlTooltip
                        title="no account connected yet"
                        placement="top"
                      >
                        <button className="small-button disabled">
                          <div className="">
                            <FontAwesomeIcon icon={faCalendarPlus} />
                          </div>
                        </button>
                      </HtmlTooltip>
                    )}

                    {channel.gateways && channel.gateways.length ? (
                      donationsEnabled ? (
                        <HtmlTooltip title="Donate" placement="top">
                          <button
                            className="small-button unset"
                            onClick={showGatewaySelector}
                          >
                            <div className="">
                              <FontAwesomeIcon icon={faHeart} />
                            </div>
                          </button>
                        </HtmlTooltip>
                      ) : (
                        <HtmlTooltip
                          title="not enough smartlikes to accept donations at the moment"
                          placement="top"
                        >
                          <button className="small-button disabled">
                            <div className="">
                              <FontAwesomeIcon icon={faHeart} />
                            </div>
                          </button>
                        </HtmlTooltip>
                      )
                    ) : (
                      <HtmlTooltip
                        title="no donation address registered yet"
                        placement="top"
                      >
                        <button className="small-button disabled">
                          <div className="">
                            <FontAwesomeIcon icon={faHeart} />
                          </div>
                        </button>
                      </HtmlTooltip>
                    )}
                  </div>
                </div>
              ),
            }}
          </Panel>
          <style jsx>{`
            .star {
              margin-right: 5px;
            }
            .repeating {
              background-color: wheat;
              padding: 5px;
              border-radius: 5px;
              width: 100%;
              margin-top: 5px;
              visibility: visible;
              /*font-family: "Roboto";*/
              font-weight: 400;
              font-size: 14px;
              /*margin-top: 5px;*/
              display: inline-flex;
              background-color: wheat;
              padding: 5px;
              border-radius: 5px;
              width: 100%;
              margin-top: 5px;
            }
            .highlighted {
              font-size: 20px;
              opacity: 0.7;
              margin-left: 10px;
              /*
              text-align: center;
              color: #39454a;

              border-radius: 50%;
              width: 22px;
              height: 22px;
              background-color: rgb(255, 213, 79);
              line-height: 24px;
              margin: auto;*/
            }
            .highlighted:hover {
              cursor: pointer;
            }

            .filterAction {
              font-size: 20px;
              opacity: 0.7;
              margin-left: 5px;
            }
            .filterAction:hover {
              cursor: pointer;
            }

            @media (hover: hover) and (pointer: fine) {
              .options {
                visibility: hidden;
              }

              article:hover .options {
                visibility: visible;
              }
            }

            .cut {
              font-size: 12px;
              font-family: Roboto;
            }
            .link {
              min-width: 50px;
              display: flex;
              align-items: center;
              text-decoration: none;
              margin-top: 3px;
            }
            .link:hover strong {
              text-decoration: underline;
            }
            .alias {
              margin-left: 0.5em;
              font-size: 12px;
              font-family: Roboto;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              flex: 1;
              display: block;
            }

            .owner {
              font-size: 12px;
              font-family: arial;
              color: rgb(96, 96, 96);
              margin: auto;
            }
            .owner:hover {
              text-decoration: underline;
              cursor: pointer;
            }
            .tooltip {
              font-family: Roboto;
              font-size: 14px;
            }
            .btn-icon {
              padding-right: 5px;
            }

            article {
              padding: 1em;
              background-color: ${css.backgroundColor};
              border: 1px solid ${css.borderColor};
              color: ${css.textColor};
              transition: 200ms background-color, 200ms border-color,
                200ms color;
              display: flex;
              justify-content: space-between;
              border-radius: 0.3em;
            }
            .info {
              display: flex;
              align-items: center;
              min-width: 0;
            }
            .info > *:not(:first-child) {
              margin-left: 1em;
            }
            .title {
              min-width: 0;
              font-family: Roboto;
              font-size: 14px;
              margin-bottom: 2px;
            }
            .title > h1,
            .title > p {
              margin: 0;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .title > h1 {
              font-size: 1.1em;
              font-weight: normal;
            }
            .title > p {
              font-size: 0.8em;
            }
            .description .external-link {
              margin-top: 0.5em;
              margin-bottom: 0.5em;
              font-family: arial;
            }
            .description .external-link > :global(a) {
              font-size: 0.8em;
              color: ${css.infoColor} !important; /* required to override Linkfy inline styles */
              transition: 200ms color;
              font-style: italic;
              text-decoration: none;
            }
            .description .external-link:hover > :global(a) {
              text-decoration: underline;
            }
            .description {
              margin-top: 5px;
            }
            .external-link {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .toolbar {
              display: flex;
              margin-top: 10px;
            }
            .toolbar > :not(:first-child) {
              margin-left: 12px;
            }
            .like-button {
              border-radius: 50%;
              height: 2.5em;
              width: 2.5em;
              color: inherit;
              background-color: transparent;
              border: 1px solid ${css.borderColor};
              transition: 200ms border-color, 200ms background-color,
                200ms color;
            }
            .like-button:not(:disabled):hover {
              background-color: ${css.hoverButtonBackgroundColor};
            }
            .subscribe-button {
              border-radius: 0.3em;
              min-width: 8em;
              height: 2.5em;
              border: none;
              color: ${css.actionButtonTextColor};
              background-color: ${css.actionButtonBackgroundColor};
              transition: 200ms background-color, 200ms text-color;
              visibility: visible;
              width: 150px;
            }
            .set {
              color: ${css.actionButtonTextColor};
              background-color: ${css.actionButtonBackgroundColorSet};
            }
            .unset {
              color: ${css.actionButtonTextColor};
              background-color: ${css.actionButtonBackgroundColorUnset};
            }
            .disabled {
              color: ${css.actionButtonTextColor};
              background-color: ${css.actionButtonBackgroundColorUnset};
              opacity: 0.5;
              cursor: default;
            }
            .small-button {
              border-radius: 0.3em;
              width: 40px;
              height: 2.5em;
              border: none;
              color: ${css.actionButtonTextColor};
              background-color: ${css.actionButtonBackgroundColor};
              transition: 200ms background-color, 200ms text-color;
              visibility: visible;
            }
            .regular-support {
              width: 120px;
              color: ${css.actionButtonTextColor};
              background-color: ${css.actionButtonBackgroundColor};
              transition: 200ms background-color, 200ms text-color;
              visibility: visible;
            }
          `}</style>
        </article>
      )}
    </>
  )
}

export default ChannelView
