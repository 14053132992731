import { useEffect, useRef, useState } from "react"
import { convertChannel } from "../services/content"
import { GET_channel, GET_donation_account } from "../services/http"
import { Channel } from "../types"
import { useFilters } from "./use-filters"
import { useUser } from "./use-user"

export const useChannel = (
  initialChannelId: string | null,
  initialSort: string
) => {
  const [channel, setChannel] = useState<Channel | null>(null)
  const { filters, updateFilters } = useFilters()
  const { updateChannelViews } = useUser()
  const [donationsEnabled, setDonationsEnabled] = useState(false)

  const firstUpdate = useRef(true)

  const load = (channelId: string) => {
    //console.log("fetching channel " + channelId)
    updateChannelViews(channelId)

    GET_channel(channelId).then(res => {
      let ch = convertChannel(res)
      //console.log(ch)
      setChannel(ch)
      if (
        ch &&
        ch.gateways &&
        ch.gateways.length > 0 &&
        ch.owner &&
        ch.owner.length > 0
      ) {
        GET_donation_account(ch.owner).then(res => {
          var v = parseFloat(res)
          if (isNaN(v) == false && v > 100) {
            setDonationsEnabled(true)
          }
          return false
        })
      }
      return false
    })
  }

  useEffect(() => {
    /*console.log(
      "initialChannelId changed ====================== " + initialChannelId
    )*/
    if (initialChannelId !== null) {
      //console.log("initialChannelId changed ====================== " + initialChannelId.length)
      if (initialChannelId.length) {
        updateFilters({
          channel_ids: [initialChannelId],
          sort: initialSort,
          q: "",
        })
      } else {
        /*console.log(
          "-initialChannelId changed ====================== " + initialSort
        )*/
        updateFilters({ channel_ids: [], sort: initialSort, q: "" })
      }
    }
  }, [initialChannelId, initialSort])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    /*console.log(
      "useEffect changed ====================== " + filters.channel_ids
    )*/
    if (filters.channel_ids && filters.channel_ids.length == 1) {
      load(filters.channel_ids[0])
    } else {
      //console.log("setting channel to null ")
      setChannel(null)
    }
  }, [filters.channel_ids])

  return {
    channel,
    setChannel,
    donationsEnabled,
  }
}
